/**
 * JavaScripts for the Site
 *
 * @project ADR_DGDA
 * @author  Mayeenul Islam
 * @since   1.0.0
 */

jQuery(document).ready(function($){

	/* ----------------------------------------------------------- */
	/*  00. Affix TopBar
	/*      using: Bootstrap Affix
	/* ----------------------------------------------------------- */
	/*$('#site-header').affix({
		offset: 15
	});*/


	/* ----------------------------------------------------------- */
	/*  01. Keyboard Layout Switcher
	/*      using: bnKb engine
	/* ----------------------------------------------------------- */

	// Field to enable Bengali
	var writing_fields     = $('.enable-bangla'),
		keyboard_choice_li = $('.choose-keyboard li');

	// Default state
	writing_fields.bnKb({
        'switchkey': {
			'webkit' : '7',
			'mozilla': '7',
			'msie'   : '7'
        },
        'driver': phonetic
    });

	// User choice
	keyboard_choice_li.on('click', function() {
		var select_choice = $(this);
			selected_val  = select_choice.data('kb');

		if( 'phonetic' === selected_val ) {
			keyboard_choice_li.removeClass('active');
			select_choice.addClass('active');
			writing_fields.bnKb({
                'switchkey': {
					'webkit' : '7',
					'mozilla': '7',
					'msie'   : '7'
                },
                'driver': phonetic
            });
		} else if( 'probhat' === selected_val ) {
			keyboard_choice_li.removeClass('active');
			select_choice.addClass('active');
			writing_fields.bnKb({
                'switchkey': {
					'webkit' : '8',
					'mozilla': '8',
					'msie'   : '8'
                },
                'driver': probhat
            });
		} else if( 'unijoy' === selected_val ) {
			keyboard_choice_li.removeClass('active');
			select_choice.addClass('active');
			writing_fields.bnKb({
                'switchkey': {
					'webkit' : '9',
					'mozilla': '9',
					'msie'   : '9'
                },
                'driver': unijoy
            });
		} else if( 'english' === selected_val ) {
			keyboard_choice_li.removeClass('active');
			select_choice.addClass('active');
			writing_fields.bnKb.toggle();
		} else {
			writing_fields.bnKb({
                'switchkey': {
					'webkit' : '7',
					'mozilla': '7',
					'msie'   : '7'
                },
                'driver': phonetic
            });
            keyboard_choice_li.removeClass('active');
			select_choice.addClass('active');
		}
	});
    
});


/* ----------------------------------------------------------- */
/*  02. Bootstrap File Select
/* 		Triggering outside document ready for newly created DOM
/*
/*  	http://www.abeautifulsite.net/whipping-file-inputs-into-shape-with-bootstrap-3/
/* ----------------------------------------------------------- */
var btn_file = $('.btn-file :file');

btn_file.on( 'change', function() {
    var input 		= $(this),
        numFiles 	= input.get(0).files ? input.get(0).files.length : 1,
        label 		= input.val().replace(/\\/g, '/').replace(/.*\//, '');
    
    input.trigger('fileselect', [numFiles, label]);
});

btn_file.on('fileselect', function(event, numFiles, label) {
	$(this).closest('.repeater-card').find('.file-status').html(label);
});
